/* styles.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

.d-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
h1,
h2 {
  color: #333;
}

input {
  margin: 5px 0;
  padding: 10px;
  width: 200px;
}

button {
  margin: 5px 0;
  padding: 10px;
}

.msger-chat::-webkit-scrollbar,
.discussions::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.discussions,
.msger-chat {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
